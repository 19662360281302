const productionConfig = {
  AUTH0_CLIENT_ID: 'JGSrENLfEp0LIAHTHja671Sde0pu0Q0Z',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  LEARN_URL: 'https://learn.acloud.guru',
  ACG_PRACTICE_EXAM_DOMAIN: 'https://practice-exam.acloud.guru',
  GRAPHQL_API_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql',
  BUGSNAG_RELEASE_STAGE: 'production',
  ACG_DOMAIN: 'https://acloud.guru',
  ACG_BUSINESS_DOMAIN: 'https://business.acloud.guru',
  SEGMENT_API_KEY: '3zPEKSq50vWgV9s9DsqEkqnRZJ5seAfD',
  PS_LOGOUT_URL: 'http://app.pluralsight.com/id/signout',
};

export { productionConfig };
